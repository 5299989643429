import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-image";
import { Helmet } from "react-helmet";
// import moment from "moment";
import Layout from "../components/layout";
import styled from "@emotion/styled";
// const _ = require("underscore");

export default function Index(props) {
  const title = "Home";
  const metaDescription = "Star Citizen info and tools";
  const metaUrl = "https://scdata.io/";
  const metaImage = "";
  return (
    <Layout>
      <Helmet>
        <title>{`ScData.io`}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="2445424502355895" />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="675" />
        <meta property="og:image:alt" content={title} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="scdata" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
        <html lang="en" />
      </Helmet>

      <Container>
        <h1>Star Citizen Info and Tools</h1>
        <span data-ccpa-link="1"></span>
        <FlexContainer>
          <Link to="/ship-cost-uec-dollar-ratio/">
            <RowFlex>
              <h3>Ship Cost UEC/Dollar Ratio</h3>
              <span>Compare the value of ships in game vs on the website</span>
            </RowFlex>
          </Link>
          <Link to="/ship-cargo-capacity/">
            <RowFlex>
              <h3>Ship Cargo Capacity</h3>
              <span>Compare the ship cargo capacities</span>
            </RowFlex>
          </Link>
          <Link to="/star-citizen-loaner-values/">
            <RowFlex>
              <h3>Ship Loaner Values Matrix</h3>
              <span>Compare the ship loaner values</span>
            </RowFlex>
          </Link>
        </FlexContainer>
      </Container>
    </Layout>
  );
}


const Container = styled.div``;
const FlexContainer = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 45em) {
    align-content: start;
    margin: 0 auto;
  }
  @media (min-width: 1200px) {
    justify-content: initial;
  }
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: 5px;
`;
